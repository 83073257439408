import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';

const Highlight = ({ highlightProperties, ...props }) => {
  if (!highlightProperties) return <></>;

  const { color, bg_color, text } = highlightProperties;
  const { className } = props;

  return (
    <span
      className={classnames(
        'highlight',
        `font-color--${color}`,
        `background-color--${bg_color}`,
        className,
      )}
    >
      {text}
    </span>
  );
};

Highlight.propTypes = {
  highlightProperties: shape({
    text: string.isRequired,
    color: string.isRequired,
    bg_color: string.isRequired,
  }),
};

export default Highlight;

